import React from 'react';
import { Provider } from './stateContext';
import { useSiteState } from './hooks';

function SiteContext({ children }) {
  const state = useSiteState();
  const { darkMode } = state;
  return (
    <Provider>
      {children}
    </Provider>
  );
}

export default SiteContext;
