module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leonar Pérez Vidal - Software Developer","short_name":"@leonarpv","start_url":"/","background_color":"#80cbc4","theme_color":"#80cbc4","display":"minimal-ui","icon":"src/images/leonarpv-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"97adcc5f62b3816dc51a3b6d9aa3c1cd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
