import React from 'react';
import SiteContext from './src/context/SiteContex';

export const wrapRootElement = ({ element }) => (
  <SiteContext>{element}</SiteContext>
);
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. '
    + 'Reload to display the latest version?',
  );

  if (answer === true) {
    window.location.reload();
  }
};
