import React, { createContext, useRef, useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { dispatchMapper } from './dispatchMapper';
import darkTheme from '../../themes/dark';
import lightTheme from '../../themes/light';

export const createContextReducer = (types, reducer, initialState) => {
  const StateContext = createContext({});
  const DispatchContext = createContext({});

  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const dispatchMapped = useRef(dispatchMapper(types, dispatch));
    return (
      <DispatchContext.Provider value={dispatchMapped.current}>
        <StateContext.Provider value={state}>
          <ThemeProvider theme={state.darkMode ? darkTheme : lightTheme}>
            {children}
          </ThemeProvider>
        </StateContext.Provider>
      </DispatchContext.Provider>
    );
  };

  return { Provider, StateContext, DispatchContext };
};
